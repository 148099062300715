import React from "react";
import { graphql, Link } from "gatsby";
import { css } from "@emotion/core";

import Layout from "../components/layout";

const StoriesPage = ({ data }) => (
  <Layout title="stories">
    <section>
      {data.stories.nodes.map((story) => (
        <div
          key={story.id}
          css={css`
            margin-bottom: 20px;
          `}
        >
          <Link to={`/stories/${story.slug.current}`}>
            <p
              css={css`
                display: inline;
                color: #ff8a00;
                margin-right: 20px;
              `}
            >
              {story.date}
            </p>
            <h3
              css={css`
                display: inline;
              `}
            >
              {story.title}
            </h3>
          </Link>
        </div>
      ))}
    </section>
  </Layout>
);

export const query = graphql`
  query StoriesPageQuery {
    stories: allSanityStory {
      nodes {
        title
        date
        slug {
          current
        }
        id
      }
    }
  }
`;

export default StoriesPage;
